<template>
  <div v-if="data">
    <h2>Annotations</h2>
    <n-space vertical justify="center" align="center" style="margin-top: 50px">
      <n-input-group>
        <n-tag :bordered="false" size="large" round
          >&nbsp;Filter category</n-tag
        >
        <n-form label-placement="left" style="width: 400px">
          <n-select
            :value="selectedCategories"
            :options="categoriesOptions"
            size="medium"
            :consistent-menu-width="false"
            multiple
            placement="bottom-start"
            @update:value="onChangeCategory"
          />
        </n-form>
      </n-input-group>
      <n-input-group>
        <n-tag :bordered="false" size="large" round
          >&nbsp;Filter keywords</n-tag
        >
        <n-form label-placement="left" style="width: 400px">
          <n-select
            :value="selectedKeywords"
            :options="keywordsOptions"
            :consistent-menu-width="false"
            size="medium"
            multiple
            @update:value="onChangeKeyword"
            filterable
            placement="bottom-start"
          />
        </n-form>
      </n-input-group>
      <n-input-group>
        <n-tag :bordered="false" size="large" round>&nbsp;Filter signals</n-tag>
        <n-form label-placement="left" style="width: 400px">
          <n-select
            :value="selectedSignals"
            :options="signalOptions"
            :consistent-menu-width="false"
            size="medium"
            multiple
            @update:value="onChangeSignal"
            filterable
            placement="bottom-start"
          />
        </n-form>
      </n-input-group>
      <n-input-group>
        <n-tag :bordered="false" size="large" round>&nbsp;Filter authors</n-tag>
        <n-form label-placement="left" style="width: 400px">
          <n-select
            :value="selectedAuthors"
            :options="authorOptions"
            :consistent-menu-width="false"
            size="medium"
            multiple
            @update:value="onChangeAuthor"
            filterable
            placement="bottom-start"
          />
        </n-form>
      </n-input-group>
      <n-input-group>
        <n-button @click="reset()">reset</n-button>
      </n-input-group>
    </n-space>
    <AnnotationListView
      :annotations="data"
      :withEdit="false"
      :withSpectrogram="true"
      @on-view-clicked="onAnnotationViewClicked"
      @on-keyword-clicked="onKeywordClicked"
      style="margin-top: 50px"
    />
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, watch } from 'vue';
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router';

import {
  NForm,
  NSpace,
  NSelect,
  NInputGroup,
  NGrid,
  NGi,
  NTag,
  NButton,
  NRadioGroup,
  NRadioButton,
  NImage,
  NEllipsis,
  NH2,
  NH5,
} from 'naive-ui';

import AnnotationListView from '@/views/AnnotationListView.vue';
import { Annotation } from '@/api/archive';
import { Category, Keyword, Author, Signal } from '@/api/classification';

const debug = process.env.NODE_ENV !== 'production';

const props = defineProps({
  query: {
    type: Object,
  },
});

const query = reactive({
  category: '',
  keyword: '',
  signal: '',
  author: '',
  ...props.query,
});

const loading = ref(false);
const data = ref(null);

const router = useRouter();
const route = useRoute();

const categories = ref([]);
const selectedCategories = computed(() => {
  return query.category === '' ? '' : query.category.split(',');
});

const keywords = ref([]);
const selectedKeywords = computed(() => {
  return query.keyword === '' ? '' : query.keyword.split(',');
});

const authors = ref([]);
const selectedAuthors = computed(() => {
  return query.author === '' ? '' : query.author.split(',');
});

const signals = ref([]);
const selectedSignals = computed(() => {
  return query.signal === '' ? '' : query.signal.split(',');
});

async function updateRoute() {
  router.push({ query: query });
  fetchData();
}

function reset() {
  query.category = '';
  query.keyword = '';
  query.signal = '';
  query.author = '';

  router.push({
    name: 'annotation',
    query: {},
  });
  fetchData();
}

const onChangeCategory = async (value) => {
  query.category = value ? value.join(',') : '';
  updateRoute();
};

const onChangeKeyword = async (value) => {
  query.keyword = value.join(',');
  updateRoute();
};

const onChangeAuthor = async (value) => {
  query.author = value.join(',');
  updateRoute();
};

const onChangeSignal = async (value) => {
  query.signal = value.join(',');
  updateRoute();
};

const categoriesOptions = computed(() => {
  if (categories.value === null) {
    return [];
  }
  return categories.value.map((c) => ({
    label: c.name,
    value: c.value,
  }));
});

const keywordsOptions = computed(() => {
  if (keywords.value === null) {
    return [];
  }
  return keywords.value.map((k) => ({
    label: k.name,
    value: k.slug,
  }));
});

const authorOptions = computed(() => {
  if (authors.value === null) {
    return [];
  }
  return authors.value.map((a) => ({
    label: a.first_name + ' ' + a.last_name,
    value: a.abbreviation,
  }));
});

const signalOptions = computed(() => {
  if (signals.value === null) {
    return [];
  }
  return signals.value.map((s) => ({
    label: s.value,
    value: s.key,
  }));
});

async function fetchData() {
  loading.value = true;
  data.value = await Annotation.filter(query);
  categories.value = await Category.all();
  keywords.value = await Keyword.all();
  authors.value = await Author.all();
  signals.value = await Signal.all();
  loading.value = false;
}

onMounted(async () => {
  await fetchData();
});

function onAnnotationViewClicked(annotation) {
  console.log(annotation);
  router.push({
    name: 'play',
    query: {
      uuid: annotation.recording.uuid,
      signal: annotation.recording.signal,
      t: annotation.start,
    },
  });
}

function onKeywordClicked(keyword) {
  selectedKeywords.value = [keyword];
  query.keyword = keyword;
  updateRoute();
}
</script>
