<template>
  <Loading v-if="isLoading" />
  <div v-else>
    <div class="faq">
      <h1>FAQ</h1>
      <n-grid :x-gap="1" :cols="12">
        <n-gi
          :offset="4"
          span="4"
          v-for="obj in objects"
          :key="obj.position"
          class="faq__item"
        >
          <h2 class="faq__item--question" v-html="obj.question"></h2>
          <p class="faq__item--answer" v-html="obj.answer"></p>
        </n-gi>
      </n-grid>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, onMounted, ref, computed } from 'vue';

import { NGrid, NGi } from 'naive-ui';

import question from '@/api/faq';
import Loading from '@/views/Loading.vue';

const debug = process.env.NODE_ENV !== 'production';

const loading = ref(false);
const objects = ref([]);

async function fetchData() {
  loading.value = true;
  objects.value = await question.list();
  loading.value = false;
}

onMounted(async () => {
  await fetchData();
});

const isLoading = computed(() => loading.value === true);
</script>

<style lang="scss" scoped>
.faq {
  &__item {
    &--question,
    &--answer {
      margin: 10px;
      padding: 10px;
    }
    &--answer {
      line-height: 2;
    }
  }
}
</style>
