<template>
  <n-config-provider
    :theme="theme"
    :themeOverrides="dark ? themeOverridesDark : themeOverridesDefault"
  >
    <n-global-style />
    <n-loading-bar-provider>
      <div id="wrapper">
        <header>
          <n-space class="navigation" justify="space-between" align="center">
            <n-menu
              style="font-weight: bolder; text-transform: uppercase"
              mode="horizontal"
              :value="activeRoute"
              :options="menuOptionsLeft"
            />
            <n-space align="center">
              <n-menu
                style="font-weight: bolder; text-transform: uppercase"
                mode="horizontal"
                :value="activeRoute"
                :options="menuOptions"
              />
              <n-menu
                style="font-weight: bolder; text-transform: uppercase"
                mode="horizontal"
                :value="activeRoute"
                :options="isLoggedIn ? userOptionsLoggedIn : userOptions"
              />
              <n-switch v-model:value="dark" :on-update:value="onChangeTheme">
                <template #unchecked-icon>
                  <n-icon :component="WeatherMoon16Filled" />
                </template>
                <template #checked-icon>
                  <n-icon :component="WeatherSunny16Regular" />
                </template>
              </n-switch>
            </n-space>
          </n-space>
        </header>
        <!-- <n-back-top :right="100" /> -->
        <main>
          <section>
            <router-view v-slot="{ Component, route }">
              <component
                :is="Component"
                :key="route.meta.usePathKey ? route.path : undefined"
              />
            </router-view>
          </section>
        </main>
      </div>
      <footer>
        <div :style="logoStyle">
          <img :src="logo" />
        </div>
        <div>
          <div>Computer Signals Audio Archive</div>
          <div>Zurich University of the Arts</div>
          <div>
            <a rel="license" href="http://creativecommons.org/licenses/by/4.0/"
              ><img
                alt="Creative Commons License"
                style="border-width: 0"
                src="https://i.creativecommons.org/l/by/4.0/80x15.png" /></a
            ><br />This work is licensed under a
            <Link
              rel="license"
              href="http://creativecommons.org/licenses/by/4.0/"
            >
              Creative Commons Attribution 4.0 International License </Link
            >.
          </div>
        </div>
        <div style="text-align: left">
          <div style="padding-bottom: 15px">funded by</div>
          <div :style="logoStyle">
            <img style="width: 80%" :src="snfLogo" />
          </div>
        </div>
      </footer>
    </n-loading-bar-provider>
  </n-config-provider>
</template>

<script setup>
import { h, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter, RouterLink } from 'vue-router';
import {
  NConfigProvider,
  NGlobalStyle,
  NSpace,
  NMenu,
  NSwitch,
  NIcon,
  darkTheme,
  NLoadingBarProvider,
  NBackTop,
  NImage,
  useThemeVars,
} from 'naive-ui';

import { WeatherMoon16Filled, WeatherSunny16Regular } from '@vicons/fluent';

import { colors } from '@/style/colors.js';

import Link from '@/components/Link.vue';

import logo from '@/assets/ZHdK_Logo.svg';
import snfLogo from '@/assets/SNF_logo_standard_web_sw_pos_e.svg';

import { Auth } from '@/api/auth';

// navigation logic
const store = useStore();
const route = useRoute();
const router = useRouter();
const activeRoute = ref(null);
watch(
  () => route.path,
  async (path) => {
    const part = path.split('/')[1];
    activeRoute.value = part;
  }
);

// store logic
store.commit('initialiseStore'); // must be done BEFORE subscribe, see @store/index.js for implementation
store.subscribe((mutation, state) => {
  // caching selective values from store to local storage
  // we could also save the state as a whole
  let cache = {
    auth: state.auth,
    stereometry: state.stereometry,
  };
  localStorage.setItem('store', JSON.stringify(cache));
});

// theme logic
const dark = ref(false);
const themeRef = useThemeVars();
if (window.localStorage.getItem('darkMode') !== null) {
  dark.value = JSON.parse(window.localStorage.getItem('darkMode'));
}
function onChangeTheme(value) {
  dark.value = value;
  window.localStorage.setItem('darkMode', dark.value);
  location.reload(); // not so nice, but works better right now.
}

// computed
const theme = computed(() => {
  return dark.value ? darkTheme : undefined;
});
const logoStyle = computed(() => {
  if (dark.value) {
    return { filter: 'invert(100%)' };
  }
});

// variables
const themeOverridesDefault = {
  common: {
    bodyColor: 'rgb(250, 250, 250)',
    bodyColorAlt: 'rgb(200, 200, 200)',
    primaryColor: colors.primaryColorDefault,
    primaryColorHover: colors.primaryColorDefault,
  },
  Switch: {
    railColorActive: '#000',
  },
};
const themeOverridesDark = {
  common: {
    bodyColor: 'rgb(0, 0, 0)',
    bodyColorAlt: 'rgb(20, 20, 20)',
    primaryColor: colors.primaryColorDark,
    primaryColorHover: colors.primaryColorDark,
  },
  Switch: {
    railColorActive: '#000',
  },
};

const isLoggedIn = computed(() => store.getters['auth/authenticated']);

function logout() {
  store.dispatch('auth/logout');
  router.push({ path: '/' });
}

const userOptionsLoggedIn = [
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'profile',
          },
        },
        { default: () => 'Profile' }
      ),
    key: 'profile',
  },
  {
    label: () =>
      h('div', {
        innerHTML: 'logout',
        onClick: logout,
      }),
    key: 'logout',
  },
];

const userOptions = [
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'login',
            query: { redirect: route.path, ...route.query },
          },
        },
        { default: () => 'Login' }
      ),
    key: 'login',
  },
];

const menuOptionsLeft = [
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'home',
          },
        },
        { default: () => 'Computer Signals Audio Archive' }
      ),
    key: 'home',
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'about',
          },
        },
        { default: () => 'ABOUT' }
      ),
    key: 'about',
  },
];

const menuOptions = [
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'year',
          },
        },
        { default: () => 'Audio' }
      ),
    key: 'year',
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'annotation',
          },
        },
        { default: () => 'Annotations' }
      ),
    key: 'annotation',
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'stereometry',
          },
        },
        { default: () => 'Stereometry' }
      ),
    key: 'stereometry',
  },
  {
    label: () =>
      h(
        RouterLink,
        {
          to: {
            name: 'faq',
          },
        },
        { default: () => 'FAQ' }
      ),
    key: 'faq',
  },
];
</script>

<style lang="scss" scoped>
footer {
  text-align: center;
}

.navigation {
  margin: 25px 25px 0px 25px;
}
</style>
